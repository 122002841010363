<template>
  <path
    d="m0 2 6.967 7.25a3 3 0 0 0 4.243.083L18.829 2h-1.442l-6.87 6.612a2 2 0 0 1-2.83-.055L1.387 2H0Z"
    fill="var(--p-color-tooltip-tail-down-border-experimental)"
  />
  <path
    d="M1.387 0h16v2l-6.87 6.612a2 2 0 0 1-2.83-.055L1.387 2V0Z"
    fill="var(--p-color-bg-surface)"
  />
</template>
