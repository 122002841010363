<template>
  <path
    d="M18.829 8.171 11.862.921A3 3 0 0 0 7.619.838L0 8.171h1.442l6.87-6.612a2 2 0 0 1 2.83.055l6.3 6.557h1.387Z"
    fill="var(--p-color-tooltip-tail-up-border-experimental)"
  />
  <path
    d="M17.442 10.171h-16v-2l6.87-6.612a2 2 0 0 1 2.83.055l6.3 6.557v2Z"
    fill="var(--p-color-bg-surface)"
  />
</template>
