<template lang="pug">
UnstyledButton(
  :class-name="classes",
  @click="handleClick",
)
  Icon(
    tone="base",
    :source="iconSource",
  )
  Text(
    as="span",
    variant="bodySm",
    font-weight="medium",
  )
    slot
</template>

<script setup lang="ts">
import { type VNode, computed } from 'vue';
import { classNames } from '@/utilities/css';
import { type VueNode } from '@/utilities/types';
import {
  UnstyledButton,
  Icon,
  Text,
} from '@/components';
import ArrowDownIcon from '@icons/ArrowDownIcon.svg';
import ArrowUpIcon from '@icons/ArrowUpIcon.svg';
import styles from '@polaris/components/IndexFilters/components/SortButton/components/DirectionButton/DirectionButton.module.css';

type DirectionButtonDirection = 'asc' | 'desc';

interface DirectionButtonProps {
  active: boolean;
  direction: DirectionButtonDirection;
  value: string;
}

const props = defineProps<DirectionButtonProps>();
const emits = defineEmits<{
  'click': [value: string[]];
}>();
const slots = defineSlots<{
  default?: (_?: VueNode) => VNode[];
}>();

const classes = computed(() => classNames(
  styles.DirectionButton,
  !!props.active && styles['DirectionButton-active'],
));
const iconSource = computed(() => props.direction === 'asc' ? ArrowUpIcon : ArrowDownIcon);

const handleClick = () => {
  emits('click', [props.value]);
};
</script>
